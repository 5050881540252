import React from 'react';

class About extends React.Component {
    render() {
        return (
            <div>
                <h1> Estas en About </h1>
            </div>
        )
    }
}

export default About;