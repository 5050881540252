import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home, About } from './pages';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

let mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#DB9996'
    }
  },
  typography: {
    "fontFamily": `"Poppins", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  props: {
    // MuiTypography: {
    //   variantMapping: {
    //     h1: 'h2',
    //     h2: 'h2',
    //     h3: 'h2',
    //     h4: 'h2',
    //     h5: 'h2',
    //     h6: 'h2',
    //     subtitle1: 'h2',
    //     subtitle2: 'h2',
    //     body1: 'span',
    //     body2: 'span',
    //   },
    // },
  }
})

function App() {
  return (
    <MuiThemeProvider theme={mainTheme}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path = "/home">
              <Home/>
            </Route>
            <Route path = "/about">
              <About/>
            </Route>
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
    
  );
}

export default App;
