import React from 'react';
import InstagramIcon from '../assets/icons/instagram-icon.png';
import FacebookIcon from '../assets/icons/facebook-icon.png';
import MumaImage from '../assets/images/muma-logo.png';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

class Home extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.main}>
                <Typography variant="h5" color="primary"> BARILOCHE, ARGENTINA </Typography>

                <div className={classes.mainImageContainer}>
                    <img  src={MumaImage} className={classes.mainImage} alt="Rincón Muma"/>
                    <Typography variant="h4" color="primary"> PÁGINA EN CONSTRUCCIÓN </Typography>
                </div>

                <div className={classes.secondaryContainer}>
                    <Typography variant="h5" color="primary"> POR CONSULTAS O VENTAS </Typography>
                    <Typography variant="h5" color="primary"> NOS PODES CONTACTAR EN INSTAGRAM </Typography>
                    <div className={classes.logoContainer}>
                        <img  src={InstagramIcon} className={classes.logo} alt="Instagram Muma"/>
                        <img  src={FacebookIcon} className={classes.logo} alt="Facebook Muma"/>
                    </div>
                </div>

                

            </div>
        )
    }
} 

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '40em',
        backgroundColor: '#FAF0E5'
    },
    mainImageContainer: {
        maxWidth: '1000px'
    },
    mainImage: {
        width: '75%',
        marginBottom: '1em'
    },
    logoContainer: {
        marginTop: '1em',
        display: 'flex',
        width: '35%',
        justifyContent: 'space-around'
    },
    logo: {
        width: '46px'
    },
    secondaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})

export default withStyles(styles)(Home);